

import axiosIntance from '../../helpers/axios';
import {bannerConstants} from './constants';




export const getBannerList = () =>{
  return async dispatch =>{
      try{
          const response = await axiosIntance.get('/banner/');
        //   console.log('action is ------------',response)
          dispatch({
              type:bannerConstants.FETCH_BANNERS,
              payload:response.data
          })
      }catch(error){
          console.log('something went wrong');
      }
  }
}
