import {supportConstants} from './constants';


const initState = {
    loading:false,
    storesSupport:[],
    msgData:'',
    singleInfo:{
        id:''
    },
}

const SupportReducer = (state = initState,action) =>{
    switch(action.type){
      case supportConstants.FETCH_SUPPORTS:
          return{
              ...state,
              storesSupport:action.payload,
              msgData:action.message,
          }
        
          
          default:
           return state
    }
}

export default SupportReducer;