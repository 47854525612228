
import {contactConstants} from './constants';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosIntance from '../../helpers/axios';




export const getWebInfoList = () =>{
    return async dispatch =>{
        try{
            const response = await axiosIntance.get('/webInfo');
            dispatch({
                type:contactConstants.WEB_INFO_LIST,
                payload:response.data
            })
        }catch(error){
            console.log('something went wrong');
        }
    }
  }

export const addContact = (formData,history) =>{
   
     return async dispatch =>{
        try{
            const response = await axiosIntance.post('/contact/add',formData);
            if(response.data.success === true){
                dispatch({
                    type:contactConstants.ADD_CONTACT,
                    payload:response.data.records
                });
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
               </button>
                if (response.data.message !== ""){
                    toast.success("Enquiry Has been Submitted Successfully", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }

            
            }
        }catch(error){

            console.log('something went wrong',error)
        }
     }
    
}

