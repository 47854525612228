

import axiosIntance from '../../helpers/axios';
import {missionConstants} from './constants';


export const getMissionList = () =>{
  return async dispatch =>{
      try{
          const response = await axiosIntance.get('/mission')
          dispatch({
              type:missionConstants.FETCH_MISSIONS,
              payload:response.data
          })
      }catch(error){
          console.log('something went wrong');
      }
  }
}

