import {missionConstants} from './constants';


const initState = {
    loading:false,
    storeMisssion:[],
    msgData:'',
    singlInfo:{
        id:''
    },
}

const MissionReducer = (state = initState,action) =>{
    switch(action.type){
      case missionConstants.FETCH_MISSIONS:
          return{
              ...state,
              storeMisssion:action.payload,
              msgData:action.message,
          }
         
          default:
           return state
    }
}

export default MissionReducer;