import {aboutPageConstants} from './constants';


const initState = {
    loading:false,
    storeImgPage:[],
    storePrinc:[],
    restAbout:[],
    msgData:'',
    singlInfo:{
        id:''
    },
}

const AboutPageReducer = (state = initState,action) =>{
 
    switch(action.type){
      case aboutPageConstants.FETCH_IMG_ABOUT_PAGE:
          return{
              ...state,
              storeImgPage:action.payload,
             
          }

          case 'FETCH_PRINCIPLES':
            return{
                ...state,
                storePrinc:action.payload,
               
            }
          

          case aboutPageConstants.FETCH_REST_ABOUT_PAGE:
            return{
                ...state,
                restAbout:action.payload,
               
            }
         
          default:
           return state
    }
}

export default AboutPageReducer;