

import axiosIntance from '../../helpers/axios';
import {supportConstants} from './constants';


export const getsSupportList = () =>{
  return async dispatch =>{
      try{
          const response = await axiosIntance.get('/support')
          dispatch({
              type:supportConstants.FETCH_SUPPORTS,
              payload:response.data
          })
      }catch(error){
          console.log('something went wrong');
      }
  }
}

