
import axiosIntance from "../../helpers/axios.js";
import {
  FETCH_CATEGORIES,
  FETCH_PARENT_CAT,
  FETCH_CATEGORIES_BY_SLUG,
} from "./constants.js";



export const FetchCategories = () =>{
    return async dispatch => {
        try{
            const response = await axiosIntance.get('/category');
            dispatch({
                type:FETCH_CATEGORIES,
                payload:response.data
            })
        }catch(error){
            console.log(error.response);
        }
    }
}


export const FetchCategoriesBySlug = (slug) =>{
    return async dispatch => {
        try{

            const response = await axiosIntance.get(`/category/getBySlug/${slug}`);
            dispatch({
                type:FETCH_CATEGORIES_BY_SLUG,
                payload:response.data.records,
                catgoryName:response.data.result
            })
        }catch(error){
            console.log(error.response);
        }
    }
}

export const FetchParentCat = () =>{
    return async dispatch => {
        try{
            const response = await axiosIntance.get('/category/parentCat');
            dispatch({
                type:FETCH_PARENT_CAT,
                payload:response.data
            })
        }catch(error){
            console.log(error.response);
        }
    }
}


