import {
  FETCH_PRODUCTS,
  FETCH_FEATURED,
  FETCH_PRODUCTS_BY_SLUG,
  FETCH_PRODUCT_DETAILS_BY_SLUG,
  ADD_CANTACT_INFO_REQUEST,
  ADD_CANTACT_INFO_SUCCESS,
} from "./constants";

const initState = {
  storeProduct: [],
  msgData: "",
  attrArrRecords: [],
  storeFeatured: [],
  storeProdSlug: [],
  prodCatName: {
    id: "",
  },
  prodDtl: {
    id: "",
  },
};

const ProductReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_FEATURED:
      return {
        ...state,
        storeFeatured: action.payload,
      };

    case FETCH_PRODUCTS:
      return {
        ...state,
        storeProduct: action.payload,
      };

    case FETCH_PRODUCTS_BY_SLUG:
      return {
        ...state,
        storeProdSlug: action.payload,
        prodCatName: action.prodCatName,
      };

    case ADD_CANTACT_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_CANTACT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case FETCH_PRODUCT_DETAILS_BY_SLUG:
      return {
        ...state,
        prodDtl: action.payload,
      };

    default:
      return state;
  }
};

export default ProductReducer;
