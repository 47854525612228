import {indsConstants} from './constants';


const initState = {
    loading:false,
    storeIndus:[],
    msgData:'',
    singlInfo:{
        id:''
    },
}

const WebInfoReducer = (state = initState,action) =>{
    switch(action.type){
      case indsConstants.FETCH_ALL_INDUS:
          return{
              ...state,
              storeIndus:action.payload,
              msgData:action.message,
          }
         
          
          default:
           return state
    }
}

export default WebInfoReducer;