import {combineReducers} from 'redux';
import carouselReducer from './container/carousel/reducer';
import missionReducer from './container/mission/reducer';
import industryReducer from './container/industries/reducer';
import clientReducer from './container/client/reducer';
import supportReducer from './container/our-support/reducer';
import abouttReducer from './container/avout-us/reducer';
import contactReducer from './container/contact/reducer';
import pageReducer from './container/page/reducer';
import categoryReducer from './container/category/reducer';
import productReducer from './container/products/reducer';

const rootReducer = combineReducers({
    banner:carouselReducer,
    mission:missionReducer,
    industry:industryReducer,
    client:clientReducer,
    support:supportReducer,
    about:abouttReducer,
    contact:contactReducer,
    page:pageReducer,
    category:categoryReducer,
    product:productReducer
   
});

export default rootReducer;