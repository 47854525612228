

import axiosIntance from '../../helpers/axios';
import {clientConstants} from './constants';

export const getClientList = () =>{
  return async dispatch =>{
      try{
          const response = await axiosIntance.get('/client/');
        //   console.log('client action is,',response)
          dispatch({
              type:clientConstants.FETCH_CLIENTS,
              payload:response.data
          })
      }catch(error){
          console.log('something went wrong');
      }
  }
}

