import {aboutConstants} from './constants';


const initState = {
    singleRec:{
        id:'',
    },
    storeSbout:[],
    msgData:'',
}

const BannerReducer = (state = initState,action) =>{
    switch(action.type){
      case aboutConstants.FETCH_ABOUTS_RECORD:
          return{
              ...state,
              singleRec:action.payload,
              msgData:action.message,
          }
          
          default:
           return state
    }
}

export default BannerReducer;