import {contactConstants} from './constants';


const initState = {
    loading:false,
    storeWebInfo:[],
    contactStore:[],
}

const contactReducer = (state = initState,action) =>{
    switch(action.type){
       case contactConstants.ADD_CONTACT:
           return {
               ...state,
               contactStore:[...state.contactStore,action.payload],
              
           }

           case contactConstants.WEB_INFO_LIST:
            return{
                ...state,
                storeWebInfo:action.payload,
            }
            
          default:
           return state
    }
}

export default contactReducer;