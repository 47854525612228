
import {indsConstants} from './constants';
import axiosIntance from '../../helpers/axios';

export const getIndustriesList = () =>{
  return async dispatch =>{
      try{
          const response = await axiosIntance.get('/ind/');
          dispatch({
              type:indsConstants.FETCH_ALL_INDUS,
              payload:response.data
          })
      }catch(error){
          console.log('something went wrong');
      }
  }
}

