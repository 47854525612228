
import axiosIntance from '../../helpers/axios';
import {ADD_CANTACT_INFO_REQUEST, ADD_CANTACT_INFO_SUCCESS, FETCH_FEATURED, FETCH_PRODUCTS_BY_SLUG, FETCH_PRODUCT_DETAILS_BY_SLUG} from './constants';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export const GetProductListuSlug = (slug) =>{
    return async dispatch =>{
        try{

            const response = await axiosIntance.get(`/product/prodBySlug/${slug}`);

            console.log(response.data);
            dispatch({
                type:FETCH_PRODUCTS_BY_SLUG,
                payload:response.data.list,
                prodCatName:response.data.prodCatName,

            })
        }catch(error){
            console.log(error.response);
        }
        
    }
}

export const GetProductDetailBySlug = (slug) =>{
    return async dispatch =>{
        try{
            const response = await axiosIntance.get(`/product/prodDtlBySlug/${slug}`);
            console.log('product-details action is',response)
            dispatch({
                type:FETCH_PRODUCT_DETAILS_BY_SLUG,
                payload:response.data,
              

            })
        }catch(error){
            console.log(error.response);
        }
        
    }
}


export const GetFeatured = () =>{
    return async dispatch =>{
        try{
            const response = await axiosIntance.get('/product/featured');
            dispatch({
                type:FETCH_FEATURED,
                payload:response.data,

            })
        }catch(error){
            console.log(error.response);
        }
        
    }
}

export const addContactInfo = (formData) =>{
    return async dispatch =>{
        try{
            dispatch({type:ADD_CANTACT_INFO_REQUEST})
            const response = await axiosIntance.post('/contact/add',formData);
            dispatch({
                type:ADD_CANTACT_INFO_SUCCESS,
                payload:response.data.records,

            })

            if (response.data.success === true){
                toast.success(`Hi ${response.data.records.fullName} ${response.data.message}`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
              }

        }catch(error){
            console.log(error.response);
        }
    }
}


