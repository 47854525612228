export const  FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const  FETCH_PRODUCTS_BY_SLUG = 'FETCH_PRODUCTS_BY_SLUG';
export const  FETCH_PRODUCT_DETAILS_BY_SLUG = 'FETCH_PRODUCT_DETAILS_BY_SLUG';
export const  FETCH_FEATURED = 'FETCH_FEATURED';
export const  ADD_PRODUCT = 'ADD_PRODUCT';
export const  DELETE_PRODUCT = 'DELETE_PRODUCT';
export const  GET_SINGLE_PRODUCT = 'GET_SINGLE_PRODUCT';
export const  UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const  FECH_ATTRIBUTE_BY_PRODUCT = 'FECH_ATTRIBUTE_BY_PRODUCT';
export const  ADD_CANTACT_INFO_REQUEST = 'ADD_CANTACT_INFO_REQUEST';
export const  ADD_CANTACT_INFO_SUCCESS = 'ADD_CANTACT_INFO_SUCCESS';