
import axiosIntance from '../../helpers/axios';
import {aboutPageConstants} from './constants';



export const getImageAboutPage = () =>{
  return async dispatch =>{
      try{
          const response = await axiosIntance.get('/aboutPage/imageRec');
          dispatch({
              type:aboutPageConstants.FETCH_IMG_ABOUT_PAGE,
              payload:response.data
          })
      }catch(error){
          console.log('something went wrong');
      }
  }
}

export const getRestAboutPage = () =>{
    return async dispatch =>{
        try{
            const response = await axiosIntance.get('/aboutPage/restRec');
            dispatch({
                type:aboutPageConstants.FETCH_REST_ABOUT_PAGE,
                payload:response.data
            })
        }catch(error){
            console.log('something went wrong');
        }
    }
  }

  export const getPrincileList = () =>{
    return async dispatch =>{
        try{
            const response = await axiosIntance.get('/principle');
            dispatch({
                type:'FETCH_PRINCIPLES',
                payload:response.data
            })
        }catch(error){
            console.log('something went wrong');
        }
    }
  }

