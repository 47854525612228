

import axiosIntance from '../../helpers/axios';
import {aboutConstants} from './constants';

export const getsingleAbout = () =>{
  return async dispatch =>{
      try{
          const response = await axiosIntance.get('about/singleRec');
         
          dispatch({
              type:aboutConstants.FETCH_ABOUTS_RECORD,
              payload:response.data
          })
      }catch(error){
          console.log('something went wrong');
      }
  }
}

