import {
    FETCH_CATEGORIES,
    FETCH_PARENT_CAT,
    FETCH_CATEGORIES_BY_SLUG,
  } from "./constants.js";


  const initialState = {
      catStore:[],
      parentCat:[],
      storeCatSlug:[],
      msgData:'',
      catDetails:{
          id:''
      }
  }

  const Category = (state = initialState, action) =>{
      
      switch(action.type){
          case FETCH_CATEGORIES:
              return {
                  ...state,
                  catStore:action.payload
              }

              case FETCH_PARENT_CAT:
                return {
                    ...state,
                    parentCat:action.payload
                }
                case FETCH_CATEGORIES_BY_SLUG:
                    return {
                        ...state,
                        storeCatSlug:action.payload,
                        catDetails:action.catgoryName
                    }

            
              default :
              return state;
      }
  }

  export default Category;