import {bannerConstants} from './constants';


const initState = {
    loading:false,
    msgData:'',
    storeBanner:[],
    singleBanner: {
        _id: ''
      },
}

const BannerReducer = (state = initState,action) =>{
    switch(action.type){
          case bannerConstants.FETCH_BANNERS:
          return{
              ...state,
              storeBanner:action.payload
          }
       
           
         
          
          default:
           return state
    }
}

export default BannerReducer;