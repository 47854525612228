import {clientConstants} from './constants';


const initState = {
    loading:false,
    storeClient:[],
    msgData:'',
    singlInfo:{
        id:''
    },
}

const ClientReducer = (state = initState,action) =>{
    switch(action.type){
      case clientConstants.FETCH_CLIENTS:
          return{
              ...state,
              storeClient:action.payload,
              msgData:action.message,
          }
         
          
          default:
           return state
    }
}

export default ClientReducer;