import { useEffect,lazy,Suspense } from "react";
import "./App.css";
import "./assets/css/style.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { getWebInfoList } from "./actions";
import { useDispatch } from "react-redux";

// import Home from "./container/home";
// import AboutPage from './container/page/about-page';
// import NotFound from './container/page/page-not-fount'
// import ProductList from "./container/products/product-list";
// import Category from "./container/category/";
// import DetailPage from './container/products/product-details';
// import Principle from './container/page/principle';

const Home = lazy(() => import('./container/home'));
const AboutPage = lazy(() => import('./container/page/about-page'));
const NotFound = lazy(() => import('./container/page/page-not-fount'));
const ProductList = lazy(() => import('./container/products/product-list'));
const Category = lazy(() => import('./container/category'));
const DetailPage = lazy(() => import('./container/products/product-details'));
const Principle = lazy(() => import('./container/page/principle'));

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    
    dispatch(getWebInfoList());
  }, []);

  return (
    <>

<Suspense fallback={
      <div className="row">
       
        <div className="col-sm-6 offset-md-6">
        <br />
        <br />
        <div class="spinner-border text-danger" role="status">
      <span class="sr-only">Loading...</span>
    </div>
      </div>
      </div>
    }>

      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about-us" exact component={AboutPage} />
          <Route path="/product/:slug" exact component={ProductList} />
          <Route path="/category/:slug" exact component={Category} />
          <Route path="/details/:slug" exact component={DetailPage} />
          <Route path="/principle" exact component={Principle} />
          <Route path="" component={NotFound} />
        </Switch>
      </Router>
      </Suspense>
    </>
  );
}

export default App;
