

const prod = 'https://technokit.com.bd/api';

export const api = prod;

export const generatePublicUrl = (fileName =>{
    return  `${api}/uploads/${fileName}`;
})

// export const api = 'http://localhost:5000/api';

// export const generatePublicUrl = (fileName =>{
//     return `http://localhost:5000/uploads/${fileName}`;
// })